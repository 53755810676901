import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Select from "react-select"
import SEO from "../../components/seo"
import usePagination from "../../hooks/usePagination"
import { marketreportURL } from "../../lib/urls"
import { capitalFirst } from "../Common/utils"
import GreenPropertyCardBadge from "../GreenPropertyCardBadge/GreenPropertyCardBadge"
import "./MarketReportListing.scss"
import GGFXImage from "../../modules/GGFXImage"
import { useLocation } from "@reach/router"
import Pagination from "../Pagination/Pagination"
import useDebounce from "../../hooks/useDebounce"



const MarketReportListing = ({
  add_page_modules,
  marketReportData,
  strapi_id,
  areaData,
  partData,
}) => {

  const totalItems = marketReportData?.length;
  const [totalCount, setTotalCount] = useState(totalItems)
  const location = useLocation()
  const itemsPerPage =  16
  const pageSize = Math.ceil(totalCount/itemsPerPage)

  let queryOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const community = result?.get("community")
    queryOption = {
      label: capitalFirst(community?.replace(/-/g, " ")),
      value: community,
    }
  }

  let periodOption
  if (typeof window !== "undefined") {
    const result1 = new URLSearchParams(window.location?.search)
    const period = result1?.get("period")
    periodOption = {
      label: capitalFirst(period)?.replace("-", " "),
      value: period,
    }
  }

  let querySearchValue;
  if(typeof window !=='undefined'){
    const query = new URLSearchParams(window.location.search);
    const searchVal = query.get('search')
    querySearchValue = {
      label: capitalFirst(searchVal?.replace(/-/g, " ")),
      value: searchVal
    }
  }
  

  let allAreaOption = {
    label: "All Area",
    value: "all",
  }
  let allPartOption = {
    label: "Time Period",
    value: "all",
  }

  let queryPeriodData = ""
  let queryData = ""
  let periodData = ""


  if (
    queryOption?.value &&
    queryOption?.value !== "all" &&
    periodOption?.value &&
    periodOption?.value !== "all"
  ) {
    let filteredArea1 = marketReportData.filter(area => {
      if (area.node.areas?.length > 0) {
        return area?.node?.areas?.some(c => c.slug?.includes(queryOption.value))
      }
    })
    let filteredAreatab2 = filteredArea1.filter(area => {
      if (area.node.quarter?.slug) {
        return area?.node?.quarter?.slug===periodOption.value
      }
    })
    queryPeriodData = filteredAreatab2
  } else if (queryOption?.value && queryOption?.value !== "all") {
    queryData = marketReportData.filter(area => {
      if (area.node.areas?.length > 0) {
        return area?.node?.areas?.some(c => c.slug?.includes(queryOption.value))
      }
    })
  } else if (
    periodOption?.value &&
    periodOption?.value !== null &&
    periodOption?.value !== "all"
  ) {
    periodData = marketReportData.filter(area => {
      if (area.node.quarter?.slug) {
        return area?.node?.quarter?.slug===periodOption.value
      }
    })
  }

  const [search, setSearch] =useState(querySearchValue?.value && querySearchValue?.value !== "" ?
      querySearchValue?.value : "")
  const debounceSearch = useDebounce(search, 800)
   const [isTyping, setIsTyping] = useState(false);

  const [selectedArea, setSelectedArea] = useState(
    queryPeriodData?.length > 0
      ? queryPeriodData
      : periodData?.length > 0
      ? periodData
      : queryData?.length > 0
      ? queryData
      : marketReportData
  )
  const [selectedOption, setSelectedOption] = useState(
    queryOption?.label && queryOption?.label !== "All"
      ? queryOption
      : allAreaOption
  )
  const [selectedOptionPart, setSelectedOptionPart] = useState(
    periodOption?.label && periodOption.label !== "All"
      ? periodOption
      : allPartOption
  )

  areaData.sort((a, b) => a.title.localeCompare(b.title))
  let areaOption = []
  areaOption.push(allAreaOption)
  // console.log(areaData)
  areaData?.forEach(element => {
    if (element.title) {
      areaOption.push({
        label: element.title,
        value: element.slug,
      })
    }
  })

  let partOption = []
  partOption.push(allPartOption)
  // console.log(areaData)
  partData?.forEach(element => {
    if (element.node.title) {
      partOption.push({
        label: element.node.title,
        value: element.node.slug,
      })
    }
  })

  // for pagination
  selectedArea?.sort(
    (a, b) => new Date(b.node?.publishedAt) - new Date(a.node?.publishedAt)
  )

  useEffect(() => {
    const splitUrl = location.pathname.split('/')
    const getPagePath = splitUrl?.length>2 ? splitUrl[3] :''
    const pageFromUrl = parseInt(getPagePath?.replace('page-', '') || 1);
    setCurrentPage(pageFromUrl);
  }, [location.pathname]);

  const match = location.pathname.match(/page-(\d+)/);
  const currentPageFromUrl = match ? parseInt(match[1]) : 1;


  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedArea,
    itemsPerPage,
    initialPage: currentPageFromUrl,
  })

  // for pagination
  const handleChange = event => {
    setSelectedOption(allAreaOption)
    setSelectedOptionPart(allPartOption)
    setSearch(event.target.value)
    setIsTyping(true)
  }

  useEffect(() => {
    setIsTyping(false);
  }, [location.pathname]);

  useEffect(()=>{
      //get page num
      const splitUrl = location.pathname.split('/')
      const getPagePath = splitUrl?.length>2 ? splitUrl[3] :''
      const currentPageNum = parseInt(getPagePath.replace('page-', '') || 1);
  
      if(debounceSearch && isTyping){
        const path = '/resources/market-reports/'
        let pathVal = [] 
        if(search){
          pathVal.push(`search=${search}`)
        }
        if(selectedOption?.value){
          pathVal.push(`community=${selectedOption?.value}`)
        }
        if(selectedOptionPart?.value){
          pathVal.push(`period=${selectedOptionPart?.value}`)
        }
        navigate(`${path}?${pathVal?.join("&")}`)
        
        let filteredArea = marketReportData
          if (search) {
            filteredArea = filteredArea.filter((area, index) =>
              area.node?.title
                ?.toLowerCase()
                ?.includes(search.toLowerCase())
            )
          } 

          if (selectedOption?.value !== "all") {
            filteredArea = filteredArea.filter((area) =>
              area.node.areas?.some((c) => c.slug === selectedOption.value)
            );
          }
        
          if (selectedOptionPart?.value !== "all") {
            filteredArea = filteredArea.filter(
              (area) => area.node.quarter?.slug === selectedOptionPart.value
            );
          }
          setSelectedArea(filteredArea);
          setTotalCount(filteredArea.length)
      }
      else if(search===""&&currentPageNum ===1){
        const path = '/resources/market-reports/'
        let pathVal = [] 
        if(search){
          pathVal.push(`search=${search}`)
        }
        if(selectedOption?.value){
          pathVal.push(`community=${selectedOption?.value}`)
        }
        if(selectedOptionPart?.value){
          pathVal.push(`period=${selectedOptionPart?.value}`)
        }
        navigate(`${path}?${pathVal?.join('&')}`)
      }
  },[debounceSearch])

  const handleTabChange = (option) => {
    setSelectedOption(option);
    setSearch("");
    let pathUrl = '/resources/market-reports/';
    let pathVal = [];
    if(option?.value){
      pathVal.push(`community=${option?.value}`)
    }
    if(selectedOptionPart?.value){
      pathVal.push(`period=${selectedOptionPart?.value}`)
    }
    const pathResult = pathVal ?.length >0 ?  `${pathUrl}?${pathVal?.join("&")}`: pathUrl
    navigate(pathResult)
  };
  
  const handlePartChange = (option) => {
    setSelectedOptionPart(option);
    setSearch("");

    let pathUrl = '/resources/market-reports/';
    let pathVal = [];
    if(selectedOption?.value){
      pathVal.push(`community=${selectedOption?.value}`)
    }
    if(option?.value){
      pathVal.push(`period=${option?.value}`)
    }
    const pathResult = pathVal ?.length >0 ?  `${pathUrl}?${pathVal?.join("&")}`: pathUrl
    navigate(pathResult)
  
    if (typeof window !== "undefined") {
      sessionStorage.setItem("market_period", JSON.stringify(option));
    }
  };
  
  // Effect to handle filtering logic
  useEffect(() => {
    if (!selectedOption && !selectedOptionPart) {
      setSelectedArea(marketReportData);
      setTotalCount(marketReportData?.length)
      return;
    }
  
    let filteredArea = marketReportData;

    if(search){
      filteredArea = filteredArea.filter((area, index) =>
        area.node?.title
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      )
    }
  
    if (selectedOption?.value !== "all") {
      filteredArea = filteredArea.filter((area) =>
        area.node.areas?.some((c) => c.slug === selectedOption.value)
      );
    }
  
    if (selectedOptionPart?.value !== "all") {
      filteredArea = filteredArea.filter(
        (area) => area.node.quarter?.slug === selectedOptionPart.value
      );
    }
  
    setSelectedArea(filteredArea);
    setTotalCount(filteredArea.length)
    // setCurrentPage(1);
  }, [selectedOption, selectedOptionPart, marketReportData, search]);

  
  
  
  var imagename = "market-report.tail_image.list_image"

  //dynamic meta;

  let defaultArea = "Dubai"
  let seoArea = selectedOption?.label ? selectedOption.label : defaultArea
  let seoTimePeriod = selectedOptionPart?.label ? selectedOptionPart.label : ""

  let seoTitle = `${defaultArea} Market report`
  let seoDesc = `Stay on top of the latest trends and news with ${defaultArea} Market report`

  if (
    selectedOption.label &&
    selectedOptionPart?.label &&
    selectedOption.value !== "all" &&
    selectedOptionPart.value !== "all"
  ) {
    seoTitle = `${seoArea} + ${seoTimePeriod} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoArea} + ${seoTimePeriod} Market report`
  } else if (selectedOption.label && selectedOption.value !== "all") {
    seoTitle = `${seoArea} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoArea} Market report`
  } else if (selectedOptionPart?.label && selectedOptionPart.value !== "all") {
    seoTitle = `${seoTimePeriod} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoTimePeriod} Market report`
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={seoTitle}
        titleTemplate={`%s | haus & haus`}
        meta={[
          {
            name: `description`,
            content: seoDesc,
          },
          {
            property: `og:title`,
            content: seoTitle,
          },
          {
            property: `og:description`,
            content: seoDesc,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: seoTitle,
          },

          {
            name: `twitter:description`,
            content: seoDesc,
          },
          {
            name: `facebook-domain-verification`,
            content: ``,
          },
        ]}
      >
        <SEO
          title={seoTitle ? seoTitle : ""}
          description={seoDesc ? seoDesc : ""}
        />
      </Helmet>
      <div className="marketreport-listing-wrapper">
        <Container>
          <div className="marketreport-sorting-section">
            <ScrollAnimation
              className="market-animation"
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              <Row>
                <Col xs={12} md={6} xl={4} className="search-fix">
                  <div className="search-box">
                    <input
                      className="form-control search"
                      placeholder="Search Communities"
                      onChange={handleChange}
                      value={search}
                    />
                    <span>
                      <i className="icon grey-search-icon" />
                    </span>
                  </div>
                </Col>
                <Col xs={6} md={3} xl={2} className="mob-right">
                  <div className="select-department">
                    <Select
                      options={areaOption}
                      placeholder={"All Area"}
                      isSearchable={false}
                      className={"category-select"}
                      classNamePrefix={"category-select"}
                      //   hideSelectedOptions={true}
                      value={selectedOption}
                      onChange={handleTabChange}
                      components={{
                        DropdownIndicator: () => (
                          <i className="icon grey-down-arrow"></i>
                        ),
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <span>
                  <i className="icon grey-down-arrow" />
                </span> */}
                  </div>
                </Col>
                <Col xs={6} md={3} xl={2} className="mob-left">
                  <div className="select-department">
                    <Select
                      options={partOption}
                      placeholder={"Time Period"}
                      isSearchable={false}
                      className={"category-select"}
                      classNamePrefix={"category-select"}
                      //   hideSelectedOptions={true}
                      value={selectedOptionPart}
                      onChange={handlePartChange}
                      components={{
                        DropdownIndicator: () => (
                          <i className="icon grey-down-arrow"></i>
                        ),
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <span>
                  <i className="icon grey-down-arrow" />
                </span> */}
                  </div>
                </Col>
              </Row>
            </ScrollAnimation>
          </div>
          <div className="marketreport-listing-section">
            {search
              ? currentItems?.map((data, index) => {
                  return (
                    <>
                      <div className="marketreport-list" key={data.node?.title}>
                        <div className="image-section">
                          <Link to={`${marketreportURL}${data.node?.slug}/`}>
                            
                            <GGFXImage
                              ImageSrc={data.node?.tail_image}
                              altText={`${data.node?.title}`}
                              imagetransforms={data.node?.ggfx_results}
                              renderer="srcSet"
                              imagename={imagename}
                              strapiID={data.node?.strapi_id}
                            />
                          </Link>
                        </div>
                        <div className="content-section">
                          <Link to={`${marketreportURL}${data.node.slug}/`}>
                            <h5>{data.node?.title}</h5>
                            {data.node?.read_time && (
                              <p>{data.node?.read_time}</p>
                            )}
                          </Link>
                        </div>
                      </div>
                      {index === 7 &&
                        add_page_modules?.map((item, i) => {
                          return (
                            item?.__typename ===
                              "STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN" && (
                              <GreenPropertyCardBadge isreportpage {...item} />
                            )
                          )
                        })}
                    </>
                  )
                })
              : currentItems?.map((data, index) => {
                  let processedImages
                  processedImages =
                    data?.node?.imagetransforms?.tail_image_Transforms ||
                    JSON.stringify({})

                  return (
                    <>
                      <ScrollAnimation
                        animateIn="animate__slideInUp"
                        animateOnce
                        delay={index * 100}
                        offset={100}
                      >
                        <div
                          className="marketreport-list"
                          key={data.node?.slug}
                        >
                          <div className="image-section">
                            <Link to={`${marketreportURL}${data.node?.slug}/`}>
                              <GGFXImage
                                ImageSrc={data.node?.tail_image}
                                altText={`${data.node?.title}`}
                                imagetransforms={data.node?.ggfx_results}
                                renderer="srcSet"
                                imagename={imagename}
                                strapiID={data.node?.strapi_id}
                              />
                            </Link>
                          </div>
                          <div className="content-section">
                            <Link to={`${marketreportURL}${data.node.slug}/`}>
                              <h5>{data.node?.title}</h5>
                              {data.node?.read_time && (
                                <p>{data.node?.read_time}</p>
                              )}
                            </Link>
                          </div>
                        </div>
                      </ScrollAnimation>
                      {index === 7 &&
                        add_page_modules?.map((item, i) => {
                          return (
                            item?.__typename ===
                              "STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN" && (
                              <GreenPropertyCardBadge isreportpage {...item} />
                            )
                          )
                        })}
                    </>
                  )
                })}
          </div>

          <Pagination
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={currentPage}
          querySelectedOption={queryOption}
          querySelectedPeriod = {periodOption}
          querySearchValue={querySearchValue}
          pagepath = 'market'
        />
        </Container>
      </div>
    </>
  )
}

export default MarketReportListing
