import React from "react"
import "./AreaGuideLanding.scss"
import { Container } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const AreaGuideLanding = ({ pageData, isPreviewEnabled }) => {
  return (
    <div className="areaguide-landing-wrapper">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="areaguide-landing-content">
          <h1>{pageData?.title}</h1>
          {(pageData?.description?.data?.description || (pageData?.description&&isPreviewEnabled))&&
          <p className="description">
            <ContentModule Content={pageData?.description?.data?.description || pageData?.description} />
          </p>
          }
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default AreaGuideLanding
